<template>
  <div class="tos">
    <h1>Terms of Service</h1>
    <p>
      By using PocketLang, you accept and agree to follow these terms. Please also
      see our <router-link to="/privacy-policy">privacy policy</router-link> for
      details of how we collect and use your information.
    </p>
    <h2>General</h2>
    <p>
      Your use of PocketLang is at your sole risk. The site is provided on an "as
      is" and "as available" basis without any express or implied warranty or
      condition.
    </p>
    <p>
      At any time, with no notice, we may modify the site's functionality.
    </p>
    <h2>Restrictions on use</h2>
    <p>
      Do not attempt to damage the operation of the site. If you discover a bug,
      security issue, or privacy leak, please disclose it responsibly by
      emailing security@pocketlang.com.
    </p>
    <h2>Your PocketLang account</h2>
    <p>
      If you register an account on PocketLang, do not share your account with
      others.
    </p>
    <p>
      Do not attempt to gain access to accounts that do not belong to you.
    </p>
  </div>
</template>

<script>
export default {
  name: 'tos',
  mounted() {
    document.title = 'Terms of Service';
  },
};
</script>

<style scoped lang="scss">
.tos {
  margin: 0 0.5rem;
}
</style>
